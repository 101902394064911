import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const OutlinedWrapper = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  padding: 8px;
  text-align: right;
  font-size: 13px;
  line-height: 1.28571428571;
  color: #868686;
`


const Sorting = ({ descending, onClick }) => {

    const { t } = useTranslation()

    return ( 
        <OutlinedWrapper onClick={() => onClick()}>
         {t(`Ceny: od`)} {descending ? t('najwyższej') : t('najniższej') }
        </OutlinedWrapper>
    )
}

export default Sorting