import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ProductCardData from '../molecules/ProductCardData'
import { fromMostExpensive, realProducts } from '../utils/utils'
import ProductGrid from '../molecules/ProductGrid'
import Wrapper from '../components/Wrapper'
import ProductPageTitle from '../molecules/ProductPageTitle'
import BreadcrumbsTycho from '../molecules/BreadcrumbsTycho'
import Sorting from '../components/Sorting'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { useQueryParam, StringParam } from 'use-query-params'
import slugify from 'slugify'
import { germany } from '../initial/vars'

const Navi = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const AllProducts = ({ pageContext: { lang } }) => {
  const data = useStaticQuery(graphql`
    query AllProducts {
      allWcProducts(
        filter: {
          stock_quantity: {ne: 0}
        }
      ) {
        edges {
          node {
            wordpress_id
            name
            short_description
            slug
            price
            categories {
              name
              slug
            }
            tags {
              name
            }
            acf {
              bullet_1
              bullet_2
              bullet_3
              euro_price
            }
            images {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, maxHeight: 520) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)


  const [filtered, setFiltered] = useState()
  const [filter] = useQueryParam('filter', StringParam)

  useEffect(() => {
    setFiltered(filter)
  }, [filter])

  const byCategory = edges => edges.node.categories.some(category => category.slug === filtered)

  const products = data.allWcProducts.edges.filter(realProducts)

  const [descending, setDescending] = useState(true);
  const [sortedProducts, sortProducts] = useState(products.sort(fromMostExpensive))

  const sortedAndFiltered = (products) => {
    const filtered = products.filter(byCategory)
    if (filtered.length === 0) {
      return products
    } else {
      return filtered
    }
  }

  const reverseOrder = () => {
    setDescending(!descending)
    sortProducts(sortedProducts.reverse())
  }
  const { t, i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang, i18n]);
  // Set the language for SSR
  if (typeof window === 'undefined') {
    i18n.changeLanguage(lang)
  }

  const getTitle = () => {
    switch (filtered) {
      case 's-series': return 'Seria S'
      case 'g-series': return 'Seria G'
      case 'tycho-loft': return 'Seria Loft'
      case 'f-series': return 'Seria F'
      case 'w-series': return 'Seria W'
      case 'l-series': return 'Seria L'
      default: return 'Produkty'
    }
  }

  const slug = (name) => {
    return slugify(t(`product::${name}`), { lower: true }).replace(/[^a-z0-9-]/gi, '')
  }

  const prefix = germany ? lang + '/' : ''


  return (
    <Layout>
      <SEO title={t(getTitle())} />
      <Wrapper>
        <ProductPageTitle text={t(getTitle())} />

        <Navi >
          <BreadcrumbsTycho>
            <Link to='/'> {t('Strona Główna')} </Link>
            {getTitle() !== 'Produkty' &&
              <Link to={`/${prefix}${slug('Produkty')}/`} >
                {t('Produkty')}
              </Link>
            }
            <> {t(getTitle())} </>
          </BreadcrumbsTycho>
          <Sorting descending={descending} onClick={reverseOrder} />
        </Navi>

      </Wrapper>

      <Wrapper productList>
        <ProductGrid>
          {sortedAndFiltered(sortedProducts)
            .map(edge => (
              <ProductCardData
                key={edge.node.wordpress_id}
                product={edge.node}
              />
            ))}
        </ProductGrid>
      </Wrapper>
    </Layout >
  )
}

export default AllProducts