import React from 'react'
import styled from 'styled-components'

const StyledBreadcrumbs = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block; 
  font-size: 13px;
  line-height: 1.28571428571;
  color: #868686;

  a {
    text-decoration: none;
    color: inherit;
  }
`

const BreadcrumbsTycho = ({ className, children }) => {

  const Separator = () => ' / '

  return (
    <StyledBreadcrumbs className={className}>
      {React.Children.map(children, (child, index) => (
        <React.Fragment key={index}>
          {child && React.cloneElement(child)}
          {(index !== children.length - 1) && child && <span> <Separator /> </span>}
        </React.Fragment>
      ))}
    </StyledBreadcrumbs>
  )
}

export default BreadcrumbsTycho
