import React from 'react'
import RegularTypo from '../../typography/RegularTypo'
import styled from 'styled-components'
import { typoLightGray } from '../../utils/theme'

const StyledTitle = styled(RegularTypo)`
  font-size: 66px;
  font-weight: 300;
  letter-spacing: -1.5px;
  margin: 90px 0 -8px -3px;
`
const ProductPageTitle = ({ text }) => <StyledTitle text={text} color={typoLightGray} />

export default ProductPageTitle
